/* Variables */
:root {
  --primary-color: #00B274;
  /* --background-color: #5A6863; */
  --dark-grey: #2e3130;
}

header .button {
  background-color: var(--dark-grey) !important;
}

.shell #products {
  flex-direction: row;
}

.shell .product#educare-plan-enhanced:before {
  background-image: url('/images/educare-icon.png');
}

.shell .product#lifetime-needs-plus:before {
  background-image: url('/images/ltn-icon.png');
}

.shell .product#family-funeral-plan-unlimited:before {
  background-image: url('/images/ffp-icon.png');
}

.shell .product#transition-plan-enhanced:before {
  background-image: url('/images/transition-icon.png');
}


.shell .product#bloom:before {
  background-image: url('/images/bloom.png');
}

.shell .product#family-income-security-plan:before {
  background-image: url('/images/fisp-icon.png');
}

.shell .product#funeral-smart-plan:before {
  background-image: url('/images/smart-funeral.svg');
}

.shell .product#savings-smart-plan:before {
  background-image: url('/images/smart-savings.svg');
}

.shell .product#living-plus-enhanced-plan:before {
  background-image: url('/images/lp-icon.png');
}

.shell .product:before {
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
  width: 130px;
  height: 130px;
  display: block;
  content: '';
  border-radius: 100px;
  background-color: #fff;
  opacity: .8;
}

.shell #products {
  background-color: #5A6863 !important;
}

@media screen and (max-width: 850px){
  .shell #products {
    padding: 50px 0 !important;
    margin-top: 60px;
  }
  .product .button {
    font-size: 18px;
    border-radius: 100px;
  }
}


@media screen and (min-width: 850px) {
  div#main > div:not(.shell) #app-version {
    left: calc(50% - 300px);
    width: 100px;
  }
}



/* Remove number input spinners */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


#additional-lives .table td:last-child #system-field-outcome p {
  display: flex;
  justify-content: space-between;
}

#additional-lives .table td,
#additional-lives .table th {
  padding-left: 5px;
  padding-right: 5px;
}


#acceptance table a { display: none !important; }

.container .button#unborn {
  display: none;
}

.button#upload-signature {
  display: block;
}